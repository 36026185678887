import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { withRouter, Route, Link } from "react-router-dom";
import { withSnackbar } from 'notistack';
import classNames from 'classnames';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import MenuIcon from '@material-ui/icons/Menu';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Avatar from '@material-ui/core/Avatar';
import Popover from '@material-ui/core/Popover';
import MenuItem from '@material-ui/core/MenuItem';
import FaceIcon from '@material-ui/icons/Face';
import router from '../router'
import axios from '../utils/axios'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import mask from '../utils/mask'

const drawerWidth = 240;

const style = theme => ({
    root: {
        display: 'flex',
        height: "100%"
    },
    drawer: {
        [theme.breakpoints.up('sm')]: {
            width: drawerWidth,
            flexShrink: 0,
        },
    },
    appBar: {
        marginLeft: drawerWidth,
        [theme.breakpoints.up('sm')]: {
            width: `calc(100% - ${drawerWidth}px)`,
        },
        background: "#fff",
    },
    menuButton: {
        marginRight: 20,
        [theme.breakpoints.up('sm')]: {
            display: 'none',
        },
    },
    toolbar: theme.mixins.toolbar,
    drawerPaper: {
        width: drawerWidth,
    },
    content: {
        flexGrow: 1,
        padding: "84px 20px 20px",
        background: theme.palette.paper,
        height: "100%",
        overflow: "auto"
    },
    mainContent:{
        width:"100%",
        height:"100%",
        overflow:"auto",
        background:"#fff",
        position:"relative"
    },
    logo: {
        lineHeight: "64px",
        color: "#FFF",
        textAlign: "center",
        fontSize: "24px",
        fontWeight: "700"
    },
    list: {
        padding: 0
    },
    listItem: {
        padding: 0,
        lineHeight: "50px",
        height: "50px",
        color: "rgba(255,255,255,0.8)",
        paddingLeft: "20px"
    },
    listItemText: {
        color: 'inherit',
        paddingLeft: "5px"
    },
    selected: {
        color: theme.palette.primary.main
    },
    avatar: {
        width: "26px",
        height: "26px",
        color:"#fff"
    },
    avatarBtn: {
        color: theme.palette.primary.main,
    }
})

class Layout extends React.Component {
    state = {
        mobileOpen: false,
        selected: "vpnclient",
        title: "VPN Client",
        userinfo: window.storage.getItem("userinfo")||{userName:"unknow"},
        anchorAdminEl: null,
        openChangePasswordDialog:false,
        oldpwd:"",
        newpwd:"",
        newpwd2:""
    }

    handleDrawerToggle = () => {
        this.setState(state => ({ mobileOpen: !state.mobileOpen }));
    };

    openAdminMenu = (event) => {
        this.setState({
            anchorAdminEl: event.currentTarget
        })
    }

    closeAdminMenu = () => {
        this.setState({
            anchorAdminEl: null
        })
    }

    logout = ()=>{
        axios.post("/admin/logout");
        window.storage.clear();
        this.props.history.push("/login");
    }

    openChangePassword=()=>{
        this.setState({
            openChangePasswordDialog: true,
            oldpwd: "",
            newpwd: "",
            newpwd2: ""
        })
    }

    handleClosePassword=()=>{
        this.setState({
            openChangePasswordDialog: false
        })
    }

    onKeyup = (e) => {
        if (e.keyCode === 13) {
            this.handleSubmit()
        }
    }

    handleChange=(name)=>(event)=>{
        this.setState({
            [name]: event.target.value.trim()
        })
    }

    handleSubmit=()=>{
        if(!this.state.oldpwd){
            this.props.enqueueSnackbar("请输入原密码", { variant: 'error' });
        }else if (this.state.newpwd !== this.state.newpwd2) {
            this.props.enqueueSnackbar("新密码两次输入不一致", { variant: 'error' });
        } else if (this.state.newpwd.length < 6) {
            this.props.enqueueSnackbar("新密码长度不能少于6位", { variant: 'error' });
        } else {
            mask.show();
            axios.post("/user/changePassword", {
                userName: window.storage.getItem("userinfo")["userName"],
                oldPassword: this.state.oldpwd,
                newPassword: this.state.newpwd
            }).then((response) => {
                mask.hide();
                this.props.enqueueSnackbar("密码修改成功", { variant: 'success' });
                this.setState({
                    openChangePasswordDialog: false
                })
            }, (err) => {
                this.props.enqueueSnackbar(err, { variant: 'error' });
                mask.hide();
            })
        }
    }

    toLink = (item) => {
        this.setState({
            selected: item.id,
            title: item.text
        })
    }

    componentDidMount() {}

    render() {
        const { classes, match } = this.props;
        const drawer = (
            <div>
                <div className={classNames(classes.toolbar, classes.logo)}>MENUSIFU</div>
                <Divider />
                <List className={classes.list}>
                    {router.map((item, index) => item.roles.indexOf(this.state.userinfo.roleId)>-1 && (
                        <Link key={index} to={`${match.url}/${item.id}`}>
                            <ListItem
                                classes={{ root: classes.listItem, selected: classes.selected }}
                                button key={item.id}
                                onClick={this.toLink.bind(this, item)}
                                selected={this.state.selected === item.id || window.location.pathname.indexOf(item.id.toLowerCase())>-1}>
                                <ListItemIcon>{item.icon}</ListItemIcon>
                                <ListItemText primary={item.text} classes={{ primary: classes.listItemText }} />
                            </ListItem>
                            <Divider />
                        </Link>
                    ))}
                </List>
            </div>
        );
        return (
            <div className={classes.root}>
                <AppBar position="fixed" className={classes.appBar}>
                    <Toolbar style={{ paddingRight: "10px" }}>
                        <Grid container spacing={8} alignItems="center">
                            <Grid item>
                                <IconButton
                                    color="inherit"
                                    aria-label="Open drawer"
                                    onClick={this.handleDrawerToggle}
                                    className={classes.menuButton}
                                >
                                    <MenuIcon />
                                </IconButton>
                                <Typography variant="h6" color="inherit" noWrap>
                                    {this.state.title}
                                </Typography>
                            </Grid>
                            <Grid item xs />
                            <Grid item>
                                <Button color="inherit" className={classes.avatarBtn} onClick={this.openAdminMenu}>
                                    <Avatar className={classes.avatar}> <FaceIcon/> </Avatar>
                                    <span style={{ color: "#333", paddingLeft: "6px", fontSize: "16px",marginTop:"3px" }}>{this.state.userinfo.userName}</span>
                                </Button>
                            </Grid>
                        </Grid>
                    </Toolbar>
                </AppBar>

                <nav className={classes.drawer}>
                    <Hidden smUp implementation="css">
                        <Drawer
                            open={this.state.mobileOpen}
                            onClose={this.handleDrawerToggle}
                            classes={{
                                paper: classes.drawerPaper,
                            }}
                        >
                            {drawer}
                        </Drawer>
                    </Hidden>
                    <Hidden xsDown implementation="css">
                        <Drawer
                            classes={{
                                paper: classes.drawerPaper,
                            }}
                            variant="permanent"
                            open
                        >
                            {drawer}
                        </Drawer>
                    </Hidden>
                </nav>

                <main className={classes.content}>
                    <div id="mainContent" className={classes.mainContent}>
                        {
                            router.map((item) => (
                                <Route key={item.id} path={`${match.path}/${item.id}`} component={item.component} />
                            ))
                        }
                    </div>
                </main>

                <Popover
                    open={Boolean(this.state.anchorAdminEl)}
                    anchorEl={this.state.anchorAdminEl}
                    onClose={this.closeAdminMenu}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                >
                    <MenuItem onClick={this.openChangePassword}>修改密码</MenuItem>
                    <MenuItem onClick={this.logout}>退出</MenuItem>
                </Popover>

                <Dialog open={this.state.openChangePasswordDialog} onClose={this.handleClosePassword} aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">修改密码</DialogTitle>
                    <DialogContent>
                        <TextField
                            margin="dense"
                            id="oldpwd"
                            label="原密码"
                            type="password"
                            value={this.state.oldpwd}
                            fullWidth
                            onChange={this.handleChange('oldpwd')}
                            onKeyUp={this.onKeyup}
                        />
                        <TextField
                            margin="dense"
                            id="newpwd"
                            label="新密码"
                            type="password"
                            value={this.state.newpwd}
                            fullWidth
                            onChange={this.handleChange('newpwd')}
                            onKeyUp={this.onKeyup}
                        />
                        <TextField
                            margin="dense"
                            id="newpwd2"
                            label="确认密码"
                            value={this.state.newpwd2}
                            type="password"
                            fullWidth
                            onChange={this.handleChange('newpwd2')}
                            onKeyUp={this.onKeyup}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleClosePassword} >
                            取消
                        </Button>
                        <Button onClick={this.handleSubmit} color="primary">
                            确定
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

export default withRouter(withStyles(style)(withSnackbar(Layout)));