import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { withSnackbar } from 'notistack';
import classNames from 'classnames';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import Pagination from "../../components/Pagination"
import { CopyToClipboard } from 'react-copy-to-clipboard';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import ToggleOffIcon from '@material-ui/icons/Block';
import ToggleOnIcon from '@material-ui/icons/CheckCircleOutline';
import EditIcon from '@material-ui/icons/Edit';
import RefreshIcon from '@material-ui/icons/Refresh';
import ChartIcon from '@material-ui/icons/Tune';
import OrderIcon from '@material-ui/icons/ImportExport'
import ArrowUp from '@material-ui/icons/ArrowUpward'
import ArrowDown from '@material-ui/icons/ArrowDownward'
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import axios from '../../utils/axios';
import mask from '../../utils/mask';
import Drawer from '@material-ui/core/Drawer';

const styles = theme => ({
    root: {
        display: "flex",
        flexDirection: "column",
        height: "100%"
    },
    searchCon: {
        padding: "30px",
        boxShadow: "0 0 10px #a9a8b1",
        marginBottom: "20px"
    },
    tableCon: {
        flex: 1,
        overflow: "auto"
    },
    textField: {
        width: "14%",
        marginRight: "3.2%"
    },
    table: {
        width: "98%",
        marginLeft: "1%"
    },
    button: {
        marginLeft: "20px",
        width: "100px"
    },
    copyBtn: {
        marginLeft: 5,
        color: "#a0adcc",
        '&:hover': {
            color: "#7d8aa9"
        }
    },
    copyBtnRoot: {
        width: 16,
        height: 16
    },
    toolTip: {
        margin: "0 20px"
    },
    green: {
        color: "#31bb37",
        fontSize: "20px"
    },
    grey: {
        fontSize: "20px"
    },
    refreshButton: {
        marginLeft: "20px",
        minWidth: "auto",
        color: "#61696f"
    },
    tableCell: {
        padding: "4px 15px 4px 15px"
    },
    enableBtn:{
        color:"rgb(0, 153, 0)",
        cursor:"pointer",
        textDecoration:"underline"
    },
    disableBtn:{
        cursor: "pointer",
        textDecoration: "underline"
    },
    detailBtn:{
        cursor:"pointer",
        opacity:"0.85"
    },
    modelCon:{
        background:"#fff",
        width:"760px",
        height:"100%",
        overflow:"auto",
        paddingBottom:"20px"
    },
    modelTitle:{
        lineHeight:"45px",
        paddingLeft:"20px",
        marginTop:"15px"
    },
    modelLine:{
        lineHeight:"30px",
        paddingLeft:"20px",
        paddingRight:"20px",
        fontSize:"16px"
    },
    detailName:{
        display:"inline-block",
        width:"200px",
        color:"#87898e"
    },
    closeModel:{
        height:"50px",
        paddingLeft:"20px",
        lineHeight:"50px",
        borderBottom:"1px solid #dedede",
        paddingTop:"10px"
    },
    closeModelBtn:{
        cursor:"pointer"
    }
});

// gray(0);        // 不在线
// green(10),      // 正常状态
// yellow(20),     // 中间状态
// red(30),        // 预警状态
const onlineObj = {
    0: require('../../static/images/online0.png'),
    10: require('../../static/images/online1.png'),
    20: require('../../static/images/online2.png'),
    30: require('../../static/images/online3.png')
}

class VPNClient extends React.Component {
    state = {
        tableData: [],
        rowsPerPage: 10,
        page: 1, // 1代表首页
        rowsCount: 0,
        search: {
			serialNo:"",
            hardwareSN: "",
            merchantId: "",
            restName: "",
            posVersion: "",
            hasMerchant: "",
            telephone: "",
            reseller:"",
            ipAddress:"",
            system: ""
        },
        openEditSN: false,
        editSN: "",
        editSNId: "",
        editRight: window.storage.getItem("userinfo")["roleId"] < 3,
        adminRight: window.storage.getItem("userinfo")["roleId"] == 1,
        openEditTV: false,
        editTVPid: "",
        editTVid: "",
        editTVpwd: "",
        orderColumn: "",
        orderType: "",
        rowDetail:{},
        openModel:false,
        ables:{
            id:0,
            current:true,
            type:"vpn",
            devId:0
        },
        openConfirm:false,
        uploadList:[],
        editRemarkId:"",
        remark:""
    }

    getList = (pageNo, pageSize, reset) => {
        let params = {
            pageNo,
            pageSize
        };
        if (!!this.state.orderColumn) {
            switch (this.state.orderColumn) {
                case "status":
                    params.orderColumn = "hardwareUsage";
                    break;
                case "online":
                    params.orderColumn = "ifOnline";
                    break;
                default:
                    break;
            }
            params.orderType = this.state.orderType;
        }
        if (!reset) {
            Object.keys(this.state.search).forEach(key => {
                if (this.state.search[key] !== "") {
                    params[key] = this.state.search[key]
                }
            });
        }
        mask.show();
        axios.get("/vpn/getVPNList", {
            params: params
        }).then((response) => {
            const data = response.pageInfo;
            this.setState({
                tableData: data.list,
                page: data.pageNum,
                rowsPerPage: pageSize,
                rowsCount: data.total
            })
            this.pagination.setPageByParent(Number(data.pageNum));
            mask.hide();
        }, (err) => {
            this.props.enqueueSnackbar(err, { variant: 'error' });
            mask.hide();
        })
    }

    orderBy = (type) => {
        let orderColumn;
        switch (type) {
            case "status":
                orderColumn = "hardwareUsage";
                break;
            case "online":
                orderColumn = "ifOnline";
                break;
            default:
                break;
        }
        let params = {
            pageNo: 1,
            pageSize: this.state.rowsPerPage
        };
        Object.keys(this.state.search).forEach(key => {
            if (this.state.search[key] !== "") {
                params[key] = this.state.search[key]
            }
        });
        if (this.state.orderColumn === type) {
            if (this.state.orderType === "") {
                this.setState({
                    orderType: "asc"
                });
                params.orderColumn = orderColumn;
                params.orderType = "asc";
            } else if (this.state.orderType === "asc") {
                this.setState({
                    orderType: "desc"
                });
                params.orderColumn = orderColumn;
                params.orderType = "desc";
            } else {
                this.setState({
                    orderType: "",
                    orderColumn: ""
                })
            }
        } else {
            this.setState({
                orderColumn: type,
                orderType: "asc"
            })
            params.orderColumn = orderColumn;
            params.orderType = "asc";
        }
        mask.show();
        axios.get("/vpn/getVPNList", {
            params: params
        }).then((response) => {
            const data = response.pageInfo;
            this.setState({
                tableData: data.list,
                page: 1,
                rowsCount: data.total
            })
            this.pagination.setPageByParent(1);
            mask.hide();
        }, (err) => {
            this.props.enqueueSnackbar(err, { variant: 'error' });
            mask.hide();
        })
    }

    onRef = (ref) => {
        this.pagination = ref
    }

    onCopy = () => {
        this.props.enqueueSnackbar("复制成功", { variant: 'success' });
    }

    handleChangePage = (newPage) => {
        this.getList(newPage, this.state.rowsPerPage)
    }

    handleChangeRowsPerPage = (size) => {
        this.getList(1, size)
    }

    handleChange = (name) => (event) => {
        this.setState({
            search: Object.assign({}, this.state.search, {
                [name]: event.target.value.trim()
            })
        })
    }

    editSN = (id, sn) => {
        this.setState({
            openEditSN: true,
            editSN: sn || "",
            editSNId: id
        })
    }

    handleCloseEditSN = () => {
        this.setState({
            openEditSN: false
        })
    }

    handleChangeEditSN = (event) => {
        this.setState({
            editSN: event.target.value
        })
    }

    handleSubmitEditSN = () => {
        mask.show();
        var SN = this.state.editSN
        axios.post("/vpn/setHardwareSN", {
            id: this.state.editSNId,
            hardwareSN: SN
        }).then((response) => {
            this.props.enqueueSnackbar("修改成功", { variant: 'success' });
            this.getList(this.state.page, this.state.rowsPerPage);
            this.setState({
                openEditSN: false
            })
            if (!!this.state.rowDetail.id) {
                this.setState({
                    rowDetail: Object.assign({}, this.state.rowDetail, {
                        hardwareSN: SN
                    })
                })
            }
            mask.hide();
        }, (err) => {
            this.props.enqueueSnackbar(err, { variant: 'error' });
            mask.hide();
        })
    }

    editRemark = (id) => {
        if (this.state.editRight){
            this.setState({
                openEditRemark: true,
                remark : this.state.rowDetail.remark||"",
                editRemarkId:id
            })
        }
    }

    handleCloseEditRemark = () => {
        this.setState({
            openEditRemark: false
        })
    }

    handleChangeEditRemark = (event) => {
        this.setState({
            remark: event.target.value
        })
    }

    handleSubmitEditRemark = () => {
        mask.show();
        var SN = this.state.editSN
        axios.post("/vpn/setVPNRemark", {
            devId: this.state.editRemarkId,
            remark: this.state.remark
        }).then((response) => {
            this.props.enqueueSnackbar("修改成功", { variant: 'success' });
            this.getList(this.state.page, this.state.rowsPerPage);
            this.setState({
                openEditRemark: false,
                rowDetail:Object.assign({},this.state.rowDetail,{
                    remark:this.state.remark
                })
            })
            mask.hide();
        }, (err) => {
            this.props.enqueueSnackbar(err, { variant: 'error' });
            mask.hide();
        })
    }

    editTV = (id, tv) => {
        this.setState({
            editTVPid: id,
            openEditTV: true,
            editTVid: !!tv ? tv.split("/")[0] : "",
            editTVpwd: !!tv ? tv.split("/")[1] : ""
        })
    }

    handleCloseEditTV = () => {
        this.setState({
            openEditTV: false
        })
    }

    handleChangeEditTVid = (event) => {
        this.setState({
            editTVid: event.target.value.trim()
        })
    }

    handleChangeEditTVpwd = (event) => {
        this.setState({
            editTVpwd: event.target.value.trim()
        })
    }

    handleSubmitEditTV = () => {
        mask.show();
        var tminfo = !!this.state.editTVid && !!this.state.editTVpwd ? (this.state.editTVid + " / " + this.state.editTVpwd) : this.state.editTVid + " " + this.state.editTVpwd;
        axios.post("/vpn/setTeamviewerInfo", {
            id: this.state.editTVPid,
            teamviewerInfo: tminfo
        }).then((response) => {
            this.props.enqueueSnackbar("修改成功", { variant: 'success' });
            this.getList(this.state.page, this.state.rowsPerPage);
            this.setState({
                openEditTV: false
            })
            if(!!this.state.rowDetail.id){
                this.setState({
                    rowDetail: Object.assign({}, this.state.rowDetail,{
                        teamviewerInfo: tminfo
                    })
                })
            }
            mask.hide();
        }, (err) => {
            this.props.enqueueSnackbar(err, { variant: 'error' });
            mask.hide();
        })
    }

    editReseller = (id, rs) => {
        this.setState({
            openEditReseller: true,
            editReseller: rs || "",
            editResellerId: id
        })
    }

    handleCloseReseller = () => {
        this.setState({
            openEditReseller: false
        })
    }

    handleChangeEditReseller = (event) => {
        this.setState({
            editReseller: event.target.value
        })
    }

    handleSubmitEditReseller= () => {
        var rs = this.state.editReseller;
        if (!rs){
            this.props.enqueueSnackbar("请输入ID", { variant: 'error' });
            return;
        };
        mask.show();
        axios.post("/vpn/setVPNReseller", {
            devId: this.state.editResellerId,
            reseller: rs
        }).then((response) => {
            this.props.enqueueSnackbar("修改成功", { variant: 'success' });
            this.getList(this.state.page, this.state.rowsPerPage);
            this.setState({
                openEditReseller: false
            })
            if (!!this.state.rowDetail.id) {
                this.setState({
                    rowDetail: Object.assign({}, this.state.rowDetail, {
                        reseller: rs
                    })
                })
            }
            mask.hide();
        }, (err) => {
            this.props.enqueueSnackbar(err, { variant: 'error' });
            mask.hide();
        })
    }

    search = () => {
        this.pagination.setPageByParent(1);
        this.setState({page: 1}, ()=>{
            this.getList(this.state.page, this.state.rowsPerPage);
        })
    }

    reset = () => {
        this.setState({
            page: 1,
            search: {
                hardwareSN:"",
                serialNo: "",
                merchantId: "",
                restName: "",
                posVersion: "",
                hasMerchant: "",
                telephone: "",
                reseller:"",
                ipAddress:"",
                system: ""
            }
        })
        this.getList(1, this.state.rowsPerPage, true)
        this.pagination.setPageByParent(1)
    }

    openToggleDialog = (devId,current,type,id)=>{
        this.setState({
            openConfirm:true,
            ables:{id,current,type,devId}
        })
    }

    handleCloseConfirm = ()=>{
        this.setState({
            openConfirm:false
        })
    }

    toggleEnable = ()=>{
        this.setState({
            openConfirm: false
        })
        mask.show();
        var url = this.state.ables.type === "vpn" ? "/vpn/setVPNEnable" : "/vpn/setDataBackup";
        var devId = this.state.ables.devId;
        var id = this.state.ables.id;
        var able = this.state.ables.current;
        var isVpn = this.state.ables.type === "vpn";
        var params={
            method: this.state.ables.current == true ? "stop" : "start",
        }
        if (this.state.ables.type === "vpn"){
            params.devId = devId
        }else{
            params.id = id
        }
        axios.post(url, params).then((response) => {
            this.props.enqueueSnackbar("修改成功", { variant: 'success' });
            this.setState({
                tableData: this.state.tableData.map((item) => {
                    if (item.id === id) {
                        item[isVpn ? "vpnEnable" :"cloudBackupEnable"] = !able
                    }
                    return item;
                })
            })
            if (isVpn) {
                this.setState({
                    rowDetail: Object.assign({}, this.state.rowDetail, {
                        vpnEnable: !able
                    })
                })
            } else {
                this.setState({
                    rowDetail: Object.assign({}, this.state.rowDetail, {
                        cloudBackupEnable: !able
                    })
                })
            }

            mask.hide();
        }, (err) => {
            this.props.enqueueSnackbar(err, { variant: 'error' });
            mask.hide();
        })
    }

    openDetail = (id)=>{
        var detail;
        for(var i=0;i<this.state.tableData.length;i++){
            if (this.state.tableData[i].id == id){
                detail = this.state.tableData[i];
                break;
            }
        }
        this.setState({
            rowDetail: detail,
            openModel:true
        })
        axios.post("/vpn/getUploadList", {
            merchantId: detail.merchantId,
        }).then((response) => {
            this.setState({
                uploadList: !!response.resList ? response.resList.map((item, idx)=>{
                    item.index = idx;
                    return item
                }):[]
            })
        }, (err) => {
            this.props.enqueueSnackbar(err, { variant: 'error' });
            mask.hide();
        })
    }
    handleCloseModel = ()=>{
        this.setState({
            rowDetail: {},
            openModel: false
        })

    }
    closeModel = ()=>{
        this.setState({
            rowDetail: {},
            openModel: false
        })
    }

    

    componentDidMount() {
        this.getList(1, this.state.rowsPerPage)
    }

    render() {
        const { classes } = this.props;

        return (
            <div className={classes.root}>
                <div className={classNames(classes.searchCon, "clearfix")}>
                    <div className={classes.searchOption}>
                        <TextField label="Software SN" className={classes.textField} value={this.state.search.serialNo} onChange={this.handleChange('serialNo')} />
                        <TextField label="Hardware SN" className={classes.textField} value={this.state.search.hardwareSN} onChange={this.handleChange('hardwareSN')} />
                        <TextField label="Restaurant Name" className={classes.textField} value={this.state.search.restName} onChange={this.handleChange('restName')} />
                        <TextField label="Merchant ID" className={classes.textField} value={this.state.search.merchantId} onChange={this.handleChange('merchantId')} />
                        <TextField label="Phone Number" className={classes.textField} value={this.state.search.telephone} onChange={this.handleChange('telephone')} />
                        <TextField label="POS Version" className={classes.textField} value={this.state.search.posVersion} onChange={this.handleChange('posVersion')} />
                        <TextField label="Resller ID" className={classes.textField} value={this.state.search.reseller} onChange={this.handleChange('reseller')} />
                        <TextField select label="其他条件" className={classes.textField} value={this.state.search.hasMerchant} onChange={this.handleChange('hasMerchant')}  >
                            <MenuItem key="0" value="0">无</MenuItem>
                            <MenuItem key="1" value="1">已分配Merchant ID</MenuItem>
                            <MenuItem key="2" value="2">未分配Merchant ID</MenuItem>
                        </TextField>
                        <TextField label="IP Address" className={classes.textField} value={this.state.search.ipAddress} onChange={this.handleChange('ipAddress')} />
                        <TextField select label="System" className={classes.textField} value={this.state.search.system} onChange={this.handleChange('system')}  >
                            <MenuItem key="0" value="mbox">mbox</MenuItem>
                            <MenuItem key="1" value="windows">windows</MenuItem>
                            <MenuItem key="2" value="Lubuntu">Lubuntu</MenuItem>
                        </TextField>
                    </div>
                    <div style={{ textAlign: "right", marginTop:"10px", height: "37px" }}>
                        <Button variant="contained" color="primary" className={classes.button} onClick={this.search}>
                            搜索
                        </Button>
                        <Button variant="contained" className={classes.button} onClick={this.reset}>
                            重置
                        </Button>
                        <Button variant="contained" className={classes.refreshButton} onClick={this.getList.bind(this, this.state.page, this.state.rowsPerPage)}>
                            <RefreshIcon />
                        </Button>
                    </div>
                </div>
                <div className={classes.tableCon}>
                    <Table className={classes.table}>
                        <TableHead>
                            <TableRow>
                                <TableCell classes={{ root: classes.tableCell }} align="center">Software SN</TableCell>
                                <TableCell classes={{ root: classes.tableCell }} align="center">Hardware SN</TableCell>
                                <TableCell classes={{ root: classes.tableCell }} align="center">Restaurant Name</TableCell>
                                <TableCell classes={{ root: classes.tableCell }} align="center">Merchant ID</TableCell>
                                <TableCell classes={{ root: classes.tableCell }} align="center">Phone Number</TableCell>
                                <TableCell classes={{ root: classes.tableCell }} align="center">POS Version</TableCell>
                                <TableCell classes={{ root: classes.tableCell }} align="center">
                                    <p style={{ cursor: "pointer" }} onClick={this.orderBy.bind(this, "status")}>Online/Rersource
                                    {
                                            this.state.orderColumn === "status" ?
                                                (this.state.orderType === "asc" ? (<ArrowDown style={{ fontSize: "13px", color: "rgb(124, 142, 185)" }} />) : (<ArrowUp style={{ fontSize: "13px", color: "rgb(124, 142, 185)" }} />)) :
                                                (<OrderIcon style={{ fontSize: "15px", color: "rgb(124, 142, 185)" }} />)
                                        }
                                    </p>
                                </TableCell>
                                <TableCell classes={{ root: classes.tableCell }} align="center">VPN Connection</TableCell>
                                <TableCell classes={{ root: classes.tableCell }} align="center">VPN Network Latency</TableCell>
                                <TableCell classes={{ root: classes.tableCell }} align="center">VPN Private IP</TableCell>
                                <TableCell classes={{ root: classes.tableCell }} align="center">Reseller ID</TableCell>
                                <TableCell classes={{ root: classes.tableCell }} align="center">System</TableCell>
                                <TableCell classes={{ root: classes.tableCell }} align="center">posMode</TableCell>
                                <TableCell classes={{ root: classes.tableCell }} align="center">Detail</TableCell>
                                {/* <TableCell classes={{root:classes.tableCell}} align="center">
                                    <p style={{ cursor: "pointer"}} onClick={this.orderBy.bind(this,"online")}>Online
                                    {
                                        this.state.orderColumn === "online" ? 
                                                (this.state.orderType === "asc" ? (<ArrowDown style={{ fontSize: "13px",color:"rgb(124, 142, 185)"}} />) : (<ArrowUp style={{ fontSize: "13px",color:"rgb(124, 142, 185)"}} />)) : 
                                        (<OrderIcon style={{fontSize:"15px",color:"rgb(124, 142, 185)"}}/>)
                                    }
                                    </p>
                                </TableCell>
                                <TableCell classes={{root:classes.tableCell}} align="center">
                                    <p style={{ cursor: "pointer" }} onClick={this.orderBy.bind(this,"status")}>Status
                                    {
                                        this.state.orderColumn === "status" ?
                                                (this.state.orderType === "asc" ? (<ArrowDown style={{ fontSize: "13px", color: "rgb(124, 142, 185)" }} />) : (<ArrowUp style={{ fontSize: "13px", color: "rgb(124, 142, 185)" }} />)) :
                                            (<OrderIcon style={{ fontSize: "15px", color: "rgb(124, 142, 185)" }} />)
                                    }
                                    </p>
                                </TableCell>
                                <TableCell classes={{root:classes.tableCell}} align="center">Network Latency</TableCell>
                                <TableCell classes={{root:classes.tableCell}} align="center">TeamViewer(id/password)</TableCell> */}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {this.state.tableData.length > 0 && this.state.tableData.map(row => (
                                <TableRow key={row.serialNo}>
                                    <TableCell classes={{ root: classes.tableCell }} align="center">
                                        {row.serialNo || ""}
                                    </TableCell>
									<TableCell classes={{ root: classes.tableCell }} align="center">
									    {row.hardwareSN || ""}
									    {
									        this.state.editRight && (
									            <Tooltip title="编辑" placement="right" classes={{ tooltipPlacementRight: classes.toolTip }}>
									                <IconButton aria-label="Edit SN" className={classes.copyBtn} onClick={this.editSN.bind(this, row.id, row.hardwareSN)}>
									                    <EditIcon classes={{ root: classes.copyBtnRoot }} />
									                </IconButton>
									            </Tooltip>
									        )
									    }
									</TableCell>
                                    <TableCell classes={{ root: classes.tableCell }} align="center">{row.restName}</TableCell>
                                    <TableCell classes={{ root: classes.tableCell }} align="center">{row.merchantId}</TableCell>
                                    <TableCell classes={{ root: classes.tableCell }} align="center">{row.telephone}</TableCell>
                                    <TableCell classes={{ root: classes.tableCell }} align="center">{row.posVersion}</TableCell>
                                    <TableCell classes={{ root: classes.tableCell }} align="center">
                                        {
                                            <Tooltip title={
                                                <React.Fragment>
                                                    <p style={{ fontSize: 14, lineHeight: "22px" }}>Resource Status</p>
                                                    <p><span>1. CPU Usage: </span>{row.cpuusage ? row.cpuusage + "%" : "(unknow)"}</p>
                                                    <p><span>2. Memory Usage: </span>{row.memoryUsage ? row.memoryUsage + "%" : "(unknow)"}</p>
                                                    <p><span>3. Disk Avaliable: </span>{row.avaiDisk ? row.avaiDisk + "G" : "(unknow)"}</p>
                                                </React.Fragment>
                                            } placement="right" classes={{ tooltipPlacementRight: classes.toolTip }}>
                                                { <img src = {onlineObj[row.usageStatus || '0']} /> }
                                            </Tooltip>
                                        }
                                    </TableCell>
                                    <TableCell classes={{ root: classes.tableCell }} align="center">
                                        {row.vpnEnable ? 
                                            (<div className={classes.enableBtn} onClick={this.openToggleDialog.bind(this, row.devId, true, 'vpn', row.id)}>Enabled</div>) : 
                                            (<div className={classes.disableBtn} onClick={this.openToggleDialog.bind(this, row.devId, false, 'vpn', row.id)}>Disabled</div>)
                                        }
                                        
                                    </TableCell>

                                    <TableCell classes={{ root: classes.tableCell }} align="center">{row.timeDiff === 0 ? ("-") : (row.timeDiff+"ms")}</TableCell>

                                    <TableCell classes={{ root: classes.tableCell }} align="center">
                                        {row.vpnIp}
                                        <CopyToClipboard text={row.vpnIp} onCopy={this.onCopy}>
                                            <Tooltip title="复制 IP" placement="right" classes={{ tooltipPlacementRight: classes.toolTip }}>
                                                <IconButton aria-label="Copy IP" className={classes.copyBtn}>
                                                    <FileCopyIcon classes={{ root: classes.copyBtnRoot }} />
                                                </IconButton>
                                            </Tooltip>
                                        </CopyToClipboard>
                                    </TableCell>

                                    <TableCell classes={{ root: classes.tableCell }} align="center">
                                        {row.reseller ? row.reseller : ""}
                                        {/* {
                                            this.state.adminRight && (
                                                <Tooltip title="编辑" placement="right" classes={{ tooltipPlacementRight: classes.toolTip }}>
                                                    <IconButton aria-label="Edit reseller" className={classes.copyBtn} onClick={this.editReseller.bind(this, row.devId, row.reseller)}>
                                                        <EditIcon classes={{ root: classes.copyBtnRoot }} />
                                                    </IconButton>
                                                </Tooltip>
                                            )
                                        } */}
                                    </TableCell>
									
									<TableCell classes={{ root: classes.tableCell }} align="center">
									    {row.system? (row.system=="LUbuntu" ?"Lubuntu":row.system):"" }
									</TableCell>

                                    <TableCell classes={{ root: classes.tableCell }} align="center">{row.posMode || ''}</TableCell>

                                    <TableCell classes={{ root: classes.tableCell }} align="center">
                                        <img onClick={this.openDetail.bind(this,row.id)} className={classes.detailBtn} src={require('../../static/images/details.png')} />
                                    </TableCell>

                                    {/* <TableCell classes={{ root: classes.tableCell }} align="center">
                                        <Tooltip title={
                                            <React.Fragment>
                                                <p style={{ fontSize: 14, lineHeight: "22px" }}>Resource Status</p>
                                                <p><span>1. CPU Usage: </span>{row.cpuusage ? row.cpuusage + "%" : "(unknow)"}</p>
                                                <p><span>2. Memory Usage: </span>{row.memoryUsage ? row.memoryUsage + "%" : "(unknow)"}</p>
                                                <p><span>3. Disk Avaliable: </span>{row.avaiDisk ? row.avaiDisk + "G" : "(unknow)"}</p>
                                            </React.Fragment>
                                        } placement="right" classes={{ tooltipPlacementRight: classes.toolTip }}>
                                            {
                                                (!row.cpuusage && !row.memoryUsage && !row.avaiDisk) ? (<ChartIcon style={{ color: "rgba(0, 0, 0, 0.26)" }} />) :
                                                    (
                                                        ((row.cpuusage && Number(row.cpuusage) >= 90) || (row.memoryUsage && Number(row.memoryUsage) >= 90) || (row.avaiDisk && Number(row.avaiDisk) <= 2)) ?
                                                            (<ChartIcon style={{ color: "#ff5e5e" }} />) :
                                                            (
                                                                ((row.cpuusage && Number(row.cpuusage) >= 50) || (row.memoryUsage && Number(row.memoryUsage) >= 50) || (row.avaiDisk && Number(row.avaiDisk) <= 5)) ?
                                                                    (<ChartIcon style={{ color: "#f3a410" }} />) : (<ChartIcon style={{ color: "#88e610" }} />)
                                                            )
                                                    )
                                            }
                                        </Tooltip>
                                    </TableCell> */}
                                    
                                    {/* <TableCell classes={{ root: classes.tableCell }} align="center">{row.teamviewerInfo || ""}
                                        {
                                            this.state.editRight && (
                                                <Tooltip title="编辑" placement="right" classes={{ tooltipPlacementRight: classes.toolTip }}>
                                                    <IconButton className={classes.copyBtn} onClick={this.editTV.bind(this, row.id, row.teamviewerInfo)}>
                                                        <EditIcon classes={{ root: classes.copyBtnRoot }} />
                                                    </IconButton>
                                                </Tooltip>
                                            )
                                        }
                                    </TableCell> */}
                                </TableRow>
                            ))}
                            {this.state.tableData.length < 1 && (
                                <TableRow>
                                    <TableCell align="center" colSpan="10">
                                        <div className="empty">
                                            <img alt="no data" src={require('../../static/images/empty.png')} />
                                            <p>暂无数据</p>
                                        </div>
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </div>
                <Pagination
                    onRef={this.onRef}
                    page={this.state.page}
                    rowsPerPageOptions={[5, 10, 20]}
                    rowsPerPage={this.state.rowsPerPage}
                    count={this.state.rowsCount}
                    onChangePage={this.handleChangePage}
                    onChangeRowsPerPage={this.handleChangeRowsPerPage}
                />

                <Dialog open={this.state.openEditSN} onClose={this.handleCloseEditSN} aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">Edit Box Hardware SN</DialogTitle>
                    <DialogContent>
                        <TextField
                            autoFocus
                            margin="dense"
                            id="hardwareSN"
                            label="Hardware SN"
                            fullWidth
                            value={this.state.editSN}
                            onChange={this.handleChangeEditSN}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleCloseEditSN} >取消</Button>
                        <Button onClick={this.handleSubmitEditSN} color="primary">确定</Button>
                    </DialogActions>
                </Dialog>

                <Dialog open={this.state.openEditTV} onClose={this.handleCloseEditTV} aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">TeamViewer Information</DialogTitle>
                    <DialogContent>
                        <TextField
                            margin="dense"
                            id="tvid"
                            label="id"
                            fullWidth
                            value={this.state.editTVid}
                            onChange={this.handleChangeEditTVid}
                        />
                        <TextField
                            margin="dense"
                            id="tvpwd"
                            label="password"
                            fullWidth
                            value={this.state.editTVpwd}
                            onChange={this.handleChangeEditTVpwd}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleCloseEditTV} >取消</Button>
                        <Button onClick={this.handleSubmitEditTV} color="primary">确定</Button>
                    </DialogActions>
                </Dialog>

                <Dialog open={this.state.openEditReseller} onClose={this.handleCloseReseller} aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">Edit Reseller</DialogTitle>
                    <DialogContent>
                        <TextField
                            margin="dense"
                            id="reseller"
                            label="Reseller ID"
                            fullWidth
                            value={this.state.editReseller}
                            onChange={this.handleChangeEditReseller}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleCloseReseller} >取消</Button>
                        <Button onClick={this.handleSubmitEditReseller} color="primary">确定</Button>
                    </DialogActions>
                </Dialog>

                <Dialog open={this.state.openEditRemark} onClose={this.handleCloseEditRemark} aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">编辑备注</DialogTitle>
                    <DialogContent>
                        <TextField
                            margin="dense"
                            id="remark"
                            label="备注"
                            fullWidth
                            multiline 
                            defaultValue={this.state.rowDetail.remark}
                            onChange={this.handleChangeEditRemark}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleCloseEditRemark} >取消</Button>
                        <Button onClick={this.handleSubmitEditRemark} color="primary">确定</Button>
                    </DialogActions>
                </Dialog>

                <Dialog open={this.state.openConfirm} onClose={this.handleCloseConfirm} aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">Confirm</DialogTitle>
                    <DialogContent>Are you sure to {this.state.ables.current ? "disable" :"enable"} it?</DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleCloseConfirm} >取消</Button>
                        <Button onClick={this.toggleEnable} color="primary">确定</Button>
                    </DialogActions>
                </Dialog>

                <Drawer anchor="right" open={this.state.openModel} onClose={this.handleCloseModel}>
                    <div className={classes.modelCon}>
                        <div className={classes.closeModel}>
                            <img className={classes.closeModelBtn} onClick={this.closeModel.bind(this)} src={require("../../static/images/close.png")} alt="关闭" />
                        </div>
                        <h3 className={classes.modelTitle}>基本信息</h3>
                        <p className={classes.modelLine} ><span className={classes.detailName}>Box Hardware SN:</span><span className={classes.detailInfo}>
                            {this.state.rowDetail.hardwareSN}
                            {
                                this.state.editRight && (
                                    <Tooltip title="编辑" placement="right" classes={{ tooltipPlacementRight: classes.toolTip }}>
                                        <IconButton aria-label="Edit SN" className={classes.copyBtn} onClick={this.editSN.bind(this, this.state.rowDetail.id, this.state.rowDetail.hardwareSN)}>
                                            <EditIcon classes={{ root: classes.copyBtnRoot }} />
                                        </IconButton>
                                    </Tooltip>)
                            }
                        </span></p>
                        <p className={classes.modelLine} ><span className={classes.detailName}>Restaurant Name:</span><span className={classes.detailInfo}>{this.state.rowDetail.restName}</span></p>
                        <p className={classes.modelLine} ><span className={classes.detailName}>Merchant ID:</span><span className={classes.detailInfo}>{this.state.rowDetail.merchantId}</span></p>
                        <p className={classes.modelLine} ><span className={classes.detailName}>Phone Number:</span><span className={classes.detailInfo}>{this.state.rowDetail.telephone}</span></p>
                        <p className={classes.modelLine} ><span className={classes.detailName}>POS Version:</span><span className={classes.detailInfo}>{this.state.rowDetail.posVersion}</span></p>
                        <p className={classes.modelLine} ><span className={classes.detailName}>Reseller ID:</span><span className={classes.detailInfo}>
                            {this.state.rowDetail.reseller || ""}
                            {/* {
                                this.state.adminRight && (
                                    <Tooltip title="编辑" placement="right" classes={{ tooltipPlacementRight: classes.toolTip }}>
                                        <IconButton className={classes.copyBtn} onClick={this.editReseller.bind(this, this.state.rowDetail.id, this.state.rowDetail.reseller)}>
                                            <EditIcon classes={{ root: classes.copyBtnRoot }} />
                                        </IconButton>
                                    </Tooltip>
                                )
                            } */}
                            </span>
                        </p>
                        <h3 className={classes.modelTitle} >远程支持</h3>
                        <p className={classes.modelLine} ><span className={classes.detailName}>Teamviewer ID & pwd:</span><span className={classes.detailInfo}>
                            {this.state.rowDetail.teamviewerInfo}
                            {
                                this.state.editRight && (
                                    <Tooltip title="编辑" placement="right" classes={{ tooltipPlacementRight: classes.toolTip }}>
                                        <IconButton className={classes.copyBtn} onClick={this.editTV.bind(this, this.state.rowDetail.id, this.state.rowDetail.teamviewerInfo)}>
                                            <EditIcon classes={{ root: classes.copyBtnRoot }} />
                                        </IconButton>
                                    </Tooltip>
                                )
                            }
                        </span></p>
                        <p className={classes.modelLine} ><span className={classes.detailName}>VPN Private IP:</span><span className={classes.detailInfo}>
                            {this.state.rowDetail.vpnIp}
                            <CopyToClipboard text={this.state.rowDetail.vpnIp} onCopy={this.onCopy}>
                                <Tooltip title="复制 IP" placement="right" classes={{ tooltipPlacementRight: classes.toolTip }}>
                                    <IconButton aria-label="Copy IP" className={classes.copyBtn}>
                                        <FileCopyIcon classes={{ root: classes.copyBtnRoot }} />
                                    </IconButton>
                                </Tooltip>
                            </CopyToClipboard>
                        </span></p>
                        <h3 className={classes.modelTitle}>功能开关</h3>
                        <p className={classes.modelLine} ><span className={classes.detailName}>M Magic VPN Enable:</span>{this.state.rowDetail.vpnEnable ?
                            (<span className={classes.enableBtn} onClick={this.openToggleDialog.bind(this, this.state.rowDetail.devId, true, "vpn", this.state.rowDetail.id)}>Enabled</span>) :
                            (<span className={classes.disableBtn} onClick={this.openToggleDialog.bind(this, this.state.rowDetail.devId, false, "vpn", this.state.rowDetail.id)}>Disabled</span>)
                        }</p>
                        <p className={classes.modelLine} ><span className={classes.detailName}>Data Backup to Cloud:</span>{this.state.rowDetail.cloudBackupEnable ?
                            (<span className={classes.enableBtn} onClick={this.openToggleDialog.bind(this, this.state.rowDetail.devId, true, "cloud", this.state.rowDetail.id)}>Enabled</span>) :
                            (<span className={classes.disableBtn} onClick={this.openToggleDialog.bind(this, this.state.rowDetail.devId, false, "cloud", this.state.rowDetail.id)}>Disabled</span>)
                        }</p>
                        <h3 className={classes.modelTitle}>备注信息</h3>
                        <p className={classes.modelLine} >
                            <FormControl fullWidth variant="filled">
                                <Input multiline="true" rowsMax="3" rowsMin="1" 
                                value={this.state.rowDetail.remark || ""} placeholder="无备注信息"
                                    onClick={this.editRemark.bind(this, this.state.rowDetail.devId)}></Input>
                            </FormControl>
                        </p>
                        <p className={classes.modelLine} ></p>
                        <h3 className={classes.modelTitle}>数据云备份</h3>
                        <Table style={{"margin":"10px","width":"calc(100% - 20px)"}}>
                            <TableHead>
                                <TableRow>
                                    <TableCell align="center">No.</TableCell>
                                    <TableCell align="center">Data Name</TableCell>
                                    <TableCell align="center">Size</TableCell>
                                    <TableCell align="center">Created Time</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {this.state.uploadList.map(row => (
                                    <TableRow key={row.name}>
                                        <TableCell component="th" scope="row">
                                            {row.index+1}
                                        </TableCell>
                                        <TableCell align="center">{row.dataName}</TableCell>
                                        <TableCell align="center">{row.dataSize} M</TableCell>
                                        <TableCell align="center">{row.createTime}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>

                    </div>
                </Drawer>
            </div>
        )
    }
}

export default withStyles(styles)(withSnackbar(VPNClient));