import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import Input from '@material-ui/core/Input';
import Button from '@material-ui/core/Button';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MenuList from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Popover from '@material-ui/core/Popover';

const styles = theme => ({
    root: {
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        height: 64,
        paddingRight: 20,
        paddingLeft: 20
    },
    text: {
        paddingLeft: 10,
        color: "#666",
        flex:1
    },
    selectRowsPerPage: {
        background: "#fff",
        padding: "0px 2px",
        color: "#666",
        fontWeight: "normal",
        width: 50,
        minWidth: 50,
        margin: "0px 3px",
        border: "1px solid #dedede",
        height: 24
    },
    selectRowsPerPageLabel: {
        fontSize: 14
    },
    bottomIcon: {
        width: 14,
        color: "#999",
        marginLeft: 2
    },
    sizeItem:{
        height:"15px",
        lineHeight:"15px",
        borderBottom:"1px solid rgba(0,0,0,0.05)",
        textAlign:"center"
    },
    currentPage:{
        width:30
    },
    pageInput:{
        textAlign:"center"
    }
});

class Pagination extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            rowsPerPageOptions: props.rowsPerPageOptions,
            page: props.page,
            anchorEl: null
        }
    }
    handleClose = (size) => {
        this.setState({
            anchorEl: null
        })
        if(size){
            this.props.onChangeRowsPerPage(Number(size));
            this.setState({
                page: 1
            })
        }
    }
    handleClick = (event) => {
        this.setState({ anchorEl: event.currentTarget });
    }
    handleChangePage=(event)=>{
        this.setState({
            page: Number(event.target.value)
        })
    }

    setPageByParent=(page)=>{
        this.setState({ page })
    }

    onKeyup=(e)=>{
        if (e.keyCode === 13) {
            this.props.onChangePage(e.target.value)
        }
    }
    handleFirstPageButtonClick = () => {
        this.setState({page:1})
        this.props.onChangePage(1);
    }

    handleBackButtonClick = () => {
        let newPage = this.state.page-1;
        this.setState({ page: newPage})
        this.props.onChangePage(newPage);
    }

    handleNextButtonClick = () => {
        let newPage = this.state.page+1;
        this.setState({ page: newPage})
        this.props.onChangePage(newPage);
    }

    handleLastPageButtonClick = (event) => {
        let newPage = Math.max(1, Math.ceil(this.props.count / this.props.rowsPerPage));
        this.setState({ page: newPage })
        this.props.onChangePage(newPage);
    }

    componentDidMount(){
        this.props.onRef(this)
    }

    render() {
        const { classes, count, rowsPerPage, page, rowsPerPageOptions } = this.props;
        return (
            <div className={classes.root}>
                <div className={classes.text}>
                    <span style={{ verticalAlign: "text-top" }}>共{Math.ceil(count / rowsPerPage)}页{count}条数据，每页</span>
                    <Button onClick={this.handleClick} variant="contained" className={classes.selectRowsPerPage} classes={{ label: classes.selectRowsPerPageLabel }}>
                        {rowsPerPage}
                        <ExpandMoreIcon className={classes.bottomIcon} />
                    </Button>
                    <span style={{ verticalAlign: "text-top" }}>条</span>
                </div>
                <IconButton
                    onClick={this.handleFirstPageButtonClick.bind(this)}
                    disabled={page < 2}
                    aria-label="首页"
                >
                    <FirstPageIcon />
                </IconButton>
                <IconButton
                    onClick={this.handleBackButtonClick.bind(this)}
                    disabled={page < 2} aria-label="上一页">
                    <KeyboardArrowLeft />
                </IconButton>
                <Input className={classes.currentPage}
                    classes={{
                        input: classes.pageInput,
                    }}
                    value={this.state.page} 
                    onChange={this.handleChangePage.bind(this)} 
                    onKeyUp={this.onKeyup}/>
                <IconButton
                    onClick={this.handleNextButtonClick.bind(this)}
                    disabled={page >= Math.ceil(count / rowsPerPage)}
                    aria-label="下一页"
                >
                    <KeyboardArrowRight />
                </IconButton>
                <IconButton
                    onClick={this.handleLastPageButtonClick.bind(this)}
                    disabled={page >= Math.ceil(count / rowsPerPage)}
                    aria-label="末页"
                >
                    <LastPageIcon />
                </IconButton>

                <Popover
                    open={Boolean(this.state.anchorEl)}
                    anchorEl={this.state.anchorEl}
                    onClose={this.handleClose.bind(this,undefined)}
                    anchorOrigin={{
                        vertical: 'center',
                        horizontal: 'left',
                    }}
                    transformOrigin={{
                        vertical: 'center',
                        horizontal: 'right',
                    }}
                >
                    {
                        rowsPerPageOptions.map((size) => (
                            <MenuItem key={size} className={classes.sizeItem} onClick={this.handleClose.bind(this, size)}>{size}</MenuItem>
                        ))
                    }
                </Popover>
            </div>
        );
    }
}

export default withStyles(styles)(Pagination);