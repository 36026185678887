import React from 'react';
import { Route, withRouter, Switch } from "react-router-dom";
import Layout from './layout/layout'
import Login from './views/login/login'
import NotFound from './views/notFound/notFound'

class App extends React.Component {
  componentWillMount() {
    // 判断是否登录
    const userinfo = window.storage.getItem("userinfo");
    if (!userinfo || window.location.pathname.indexOf("login") > -1) {
      this.props.history.push("/login");
    } else if (!window.location.pathname || window.location.pathname === "/" || window.location.pathname === "/index" || window.location.pathname.indexOf(".html")>-1) {
      this.props.history.push("/index/vpnclient");
    }
  }
  render() {
    return (
      <div style={{ height: "100%" }}>
        <Switch>
          <Route path="/login" component={Login} />
          <Route path="/index" component={Layout} />
          <Route component={NotFound} />
        </Switch>
      </div>
    );
  }
}

export default withRouter(App);
