import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { withSnackbar } from 'notistack';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Pagination from "../../components/Pagination"
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import axios from '../../utils/axios';
import mask from '../../utils/mask';
import Checkbox from '@material-ui/core/Checkbox';

const styles = theme => ({
    root: {
        display: "flex",
        flexDirection: "column",
        height: "100%"
    },
    searchCon: {
        padding: "30px",
        boxShadow: "0 0 10px #a9a8b1",
        marginBottom: "20px"
    },
    tableCon: {
        flex: 1,
        overflow: "auto"
    },
    textField: {
        width: "14%",
        marginRight: "3.2%"
    },
    table: {
        width: "98%",
        marginLeft: "1%"
    },
    button: {
        marginLeft: "20px",
        width: "100px"
    },
    copyBtn: {
        marginLeft: 5,
        color: "#a0adcc",
        '&:hover': {
            color: "#7d8aa9"
        }
    },
    copyBtnRoot: {
        width: 16,
        height: 16
    },
    toolTip: {
        margin: "0 20px"
    },
    green: {
        color: "#31bb37",
        fontSize: "28px"
    },
    refreshButton: {
        marginLeft: "20px",
        minWidth: "auto",
        color: "#61696f"
    }
});

class UserManager extends React.Component {
    state = {
        tableData: [],
        rowsPerPage: 10,
        page: 1, // 1代表首页
        rowsCount: 0,
        openEdit: false,
        editData: {
            id: "",
            userName: "",
            realName: "",
            status: 1,
            roleId: 2,
            reseller: "",
            resellerEnable: false
        },
        openDelete: false,
        deleteId: ""
    }

    getList = (pageNo, pageSize) => {
        let params = {
            pageNo,
            pageSize
        };
        mask.show();
        axios.get("/admin/queryUserPage", {
            params: params
        }).then((response) => {
            const data = response.pageInfo;
            this.setState({
                tableData: data.list,
                page: data.pageNum,
                rowsPerPage: pageSize,
                rowsCount: data.total
            })
            mask.hide();
        }, (err) => {
            this.props.enqueueSnackbar(err, { variant: 'error' });
            mask.hide();
        })
    }

    onRef = (ref) => {
        this.pagination = ref
    }

    handleChangePage = (newPage) => {
        this.getList(newPage, this.state.rowsPerPage)
    }

    handleChangeRowsPerPage = (size) => {
        this.getList(1, size)
    }

    edit = (item) => {
        this.setState({
            openEdit: true,
            editData: {
                id: item.id,
                realName: item.realName || "",
                userName: item.userName || "",
                status: item.status,
                roleId: item.roleId,
                reseller: item.reseller,
                resellerEnable: item.resellerEnable
            }
        })
    }

    create = () => {
        this.setState({
            openEdit: true,
            editData: {
                id: "",
                realName: "",
                userName: "",
                status: 1,
                roleId: 2,
                reseller: "",
                resellerEnable: false
            }
        })
    }

    handleCloseEdit = () => {
        this.setState({
            openEdit: false
        })
    }

    handleChange = (name) => (event) => {
        this.setState({
            editData: Object.assign({}, this.state.editData, {
                [name]: event.target.value.trim()
            })
        })
    }

    handleChangeStatus = (event) => {
        this.setState({
            editData: Object.assign({}, this.state.editData, {
                status: Number(event.target.value)
            })
        })
    }

    handleChangeRole = (event) => {
        this.setState({
            editData: Object.assign({}, this.state.editData, {
                roleId: Number(event.target.value)
            })
        })
    }

    handleChangeReseller = (event) => {
        this.setState({
            editData: Object.assign({}, this.state.editData, {
                resellerEnable: event.target.checked
            })
        })
    }

    handleSubmitEdit = () => {
        if (this.state.editData.resellerEnable && !this.state.editData.reseller) {
            this.props.enqueueSnackbar("请输入经销商编号", { variant: 'error' });
            return;
        }
        mask.show();
        if (!!this.state.editData.id) {
            axios.post("/admin/updateUser", this.state.editData).then((response) => {
                this.props.enqueueSnackbar("修改成功", { variant: 'success' });
                this.getList(this.state.page, this.state.rowsPerPage);
                this.setState({
                    openEdit: false
                })
                mask.hide();
            }, (err) => {
                this.props.enqueueSnackbar(err, { variant: 'error' });
                mask.hide();
            })
        } else {
            let data = this.state.editData;
            delete data.id;
            axios.post("/admin/addUser", data).then((response) => {
                this.props.enqueueSnackbar("创建成功", { variant: 'success' });
                this.getList(this.state.page, this.state.rowsPerPage);
                this.setState({
                    openEdit: false
                })
                mask.hide();
            }, (err) => {
                this.props.enqueueSnackbar(err, { variant: 'error' });
                mask.hide();
            })
        }
    }

    del = (id) => {
        this.setState({
            openDelete: true,
            deleteId: id
        })
    }

    handleCloseDelete = () => {
        this.setState({
            openDelete: false
        })
    }

    handleSubmitDelete = () => {
        this.setState({
            openDelete: false
        });
        mask.show();
        axios.post("/admin/deleteUser", {
            id: this.state.deleteId
        }).then((response) => {
            this.props.enqueueSnackbar("删除成功", { variant: 'success' });
            this.getList(this.state.page, this.state.rowsPerPage);
            mask.hide();
        }, (err) => {
            this.props.enqueueSnackbar(err, { variant: 'error' });
            mask.hide();
        })
    }

    componentDidMount() {
        this.getList(1, this.state.rowsPerPage)
    }

    render() {
        const { classes } = this.props;

        return (
            <div className={classes.root}>
                <div style={{ textAlign: "right", paddingTop: "30px", height: "100px", paddingRight: "20px" }}>
                    <Button variant="contained" className={classes.button} onClick={this.create}>
                        创建账号
                    </Button>
                </div>
                <div className={classes.tableCon}>
                    <Table className={classes.table}>
                        <TableHead>
                            <TableRow>
                                <TableCell align="center">id</TableCell>
                                <TableCell align="center">用户账号</TableCell>
                                <TableCell align="center">用户姓名</TableCell>
                                <TableCell align="center">有效性</TableCell>
                                <TableCell align="center">角色</TableCell>
                                <TableCell align="center">经销商编号</TableCell>
                                <TableCell align="center">操作</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {this.state.tableData.length > 0 && this.state.tableData.map(row => (
                                <TableRow key={row.id}>
                                    <TableCell component="th" scope="row" align="center">
                                        {row.id}
                                    </TableCell>
                                    <TableCell component="th" scope="row" align="center">
                                        {row.userName}
                                    </TableCell>
                                    <TableCell component="th" scope="row" align="center">
                                        {row.realName}
                                    </TableCell>
                                    <TableCell component="th" scope="row" align="center">
                                        {row.status === 1 && ("有效")}
                                        {row.status === 2 && ("无效")}
                                    </TableCell>
                                    <TableCell component="th" scope="row" align="center">
                                        {row.roleId === 1 && ("超级管理员")}
                                        {row.roleId === 2 && ("高级用户")}
                                        {row.roleId === 3 && ("普通用户")}
                                    </TableCell>
                                    <TableCell component="th" scope="row" align="center">
                                        {
                                            row.resellerEnable ? (row.reseller||""):""
                                        }
                                    </TableCell>

                                    <TableCell align="center">
                                        {
                                            row.roleId !== 1 && (
                                                <div>
                                                    <Tooltip title="编辑" placement="right" classes={{ tooltipPlacementRight: classes.toolTip }}>
                                                        <IconButton aria-label="Edit" className={classes.copyBtn} onClick={this.edit.bind(this, row)}>
                                                            <EditIcon classes={{ root: classes.copyBtnRoot }} />
                                                        </IconButton>
                                                    </Tooltip>
                                                    <Tooltip title="删除" placement="right" classes={{ tooltipPlacementRight: classes.toolTip }}>
                                                        <IconButton aria-label="Edit" className={classes.copyBtn} onClick={this.del.bind(this, row.id)}>
                                                            <DeleteIcon classes={{ root: classes.copyBtnRoot }} />
                                                        </IconButton>
                                                    </Tooltip>
                                                </div>
                                            )
                                        }
                                    </TableCell>
                                </TableRow>
                            ))}
                            {this.state.tableData.length < 1 && (
                                <TableRow>
                                    <TableCell align="center" colSpan="7">
                                        <div className="empty">
                                            <img alt="no data" src={require('../../static/images/empty.png')} />
                                            <p>暂无数据</p>
                                        </div>
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </div>
                <Pagination
                    onRef={this.onRef}
                    page={this.state.page}
                    rowsPerPageOptions={[5, 10, 20]}
                    rowsPerPage={this.state.rowsPerPage}
                    count={this.state.rowsCount}
                    onChangePage={this.handleChangePage}
                    onChangeRowsPerPage={this.handleChangeRowsPerPage}
                />

                <Dialog open={this.state.openEdit} onClose={this.handleCloseEdit} aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">编辑用户</DialogTitle>
                    <DialogContent>

                        <TextField margin="dense" id="userName" label="用户账号"
                            fullWidth value={this.state.editData.userName} onChange={this.handleChange("userName")} />

                        <TextField margin="dense" id="realName" label="用户姓名"
                            fullWidth value={this.state.editData.realName} onChange={this.handleChange("realName")} />

                        <FormLabel component="legend" style={{ marginTop: "25px", marginBottom: "5px" }}>有效性</FormLabel>
                        <RadioGroup aria-label="position" name="position" value={this.state.editData.status} onChange={this.handleChangeStatus} row>
                            <FormControlLabel style={{ width: "50%" }} value={1} control={<Radio color="primary" />}
                                label="有效"
                            />
                            <FormControlLabel value={2} control={<Radio color="primary" />}
                                label="无效"
                            />
                        </RadioGroup>

                        <FormLabel component="legend" style={{ marginTop: "25px", marginBottom: "5px" }}>角色</FormLabel>
                        <RadioGroup aria-label="position" name="position" value={this.state.editData.roleId} onChange={this.handleChangeRole} row>
                            <FormControlLabel style={{ width: "50%" }} value={2} control={<Radio color="primary" />}
                                label="高级账户"
                            />
                            <FormControlLabel value={3} control={<Radio color="primary" />}
                                label="普通账户"
                            />
                        </RadioGroup>

                        < FormControlLabel label = "经销商用户"
                            control={
                                < Checkbox checked = {this.state.editData.resellerEnable}
                                onChange = {this.handleChangeReseller}
                                color = "primary" / >
                            } />
                        {
                            this.state.editData.resellerEnable &&
                            (< TextField margin="dense" id="reseller" label="经销商编号"
                                fullWidth value={ this.state.editData.reseller }
                                onChange={ this.handleChange("reseller") }
                            />)
                        }

                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleCloseEdit} >取消</Button>
                        <Button onClick={this.handleSubmitEdit} color="primary">确定</Button>
                    </DialogActions>
                </Dialog>

                <Dialog open={this.state.openDelete} onClose={this.handleCloseDelete} aria-labelledby="form-dialog-title">
                    <DialogContent>
                        确定删除此用户？
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleCloseDelete} >取消</Button>
                        <Button onClick={this.handleSubmitDelete} color="primary">确定</Button>
                    </DialogActions>
                </Dialog>

            </div>
        )
    }
}

export default withStyles(styles)(withSnackbar(UserManager));