/**
 * 基于axios的ajax方法
 */
import axios from 'axios'

const instance = axios.create();
// instance.defaults.headers.common['merchantId'] = window.storage.getItem("merchantId");
instance.defaults.headers.post['Content-Type'] = 'application/json;charset=UTF-8';

instance.interceptors.request.use(function (config) {
    return config;
}, function (error) {
    return Promise.reject(error);
});

instance.interceptors.response.use((response) => {
    if (response.headers["content-type"]==="text/html"){
        window.storage.clear();
        window.location = window.location.origin + "/login";
    }
    if (response.data.resCode === "01") {
        return response.data;
    } else {
        return Promise.reject(response.data.resMessage);
    }
}, (error) => {
    console.log(error)
    if (String(error).indexOf("Request failed")>-1){
        return Promise.reject("请求失败，请检查网络设置");
    }else{
        window.storage.clear();
        setTimeout(() => {
            window.location = window.location.origin + "/login";
        }, 1500);
        return Promise.reject("请重新登陆");
    }
});
export default instance;