import React from 'react';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
    root: {
        fontSize:"50px",
        textAlign:"center",
        marginTop:"50px"
    },
});

class NotFound extends React.Component {
    state = {}

    componentDidMount() {}

    render() {
        const { classes } = this.props;
        return (
            <div className={classes.root}>
                NOT FOUND
            </div>
        )
    }
}

export default withStyles(styles)(NotFound);