var mask = {
    show:function(parentDom){
        if (!parentDom && document.getElementById("mainContent")){
            parentDom = document.getElementById("mainContent").getElementsByTagName("div")[0]
        } else if (!parentDom){
            parentDom = document.getElementsByTagName("body")[0];
        }
        if(document.getElementById("paperMask")){
            document.getElementById("paperMask").style.display="block";
        } else{
            var maskDom = document.createElement("div");
            maskDom.id="paperMask";
            maskDom.style.display="none";
            maskDom.innerHTML='<svg viewBox = "0 0 120 120" version = "1.1" xmlns = "http://www.w3.org/2000/svg" xmlns: xlink = "http://www.w3.org/1999/xlink" ><g id="circle" class="g-circles g-circles--v3"> <circle id="12" transform="translate(35, 16.698730) rotate(-30) translate(-35, -16.698730) " cx="35" cy="16.6987298" r="10"></circle> <circle id="11" transform="translate(16.698730, 35) rotate(-60) translate(-16.698730, -35) " cx="16.6987298" cy="35" r="10"></circle> <circle id="10" transform="translate(10, 60) rotate(-90) translate(-10, -60) " cx="10" cy="60" r="10"></circle> <circle id="9" transform="translate(16.698730, 85) rotate(-120) translate(-16.698730, -85) " cx="16.6987298" cy="85" r="10"></circle> <circle id="8" transform="translate(35, 103.301270) rotate(-150) translate(-35, -103.301270) " cx="35" cy="103.30127" r="10"></circle> <circle id="7" cx="60" cy="110" r="10"></circle> <circle id="6" transform="translate(85, 103.301270) rotate(-30) translate(-85, -103.301270) " cx="85" cy="103.30127" r="10"></circle> <circle id="5" transform="translate(103.301270, 85) rotate(-60) translate(-103.301270, -85) " cx="103.30127" cy="85" r="10"></circle> <circle id="4" transform="translate(110, 60) rotate(-90) translate(-110, -60) " cx="110" cy="60" r="10"></circle> <circle id="3" transform="translate(103.301270, 35) rotate(-120) translate(-103.301270, -35) " cx="103.30127" cy="35" r="10"></circle> <circle id="2" transform="translate(85, 16.698730) rotate(-150) translate(-85, -16.698730) " cx="85" cy="16.6987298" r="10"></circle> <circle id="1" cx="60" cy="10" r="10"></circle> </g> </svg>';
            parentDom.style.overflow = "hidden";
            parentDom.appendChild(maskDom);
            setTimeout(function(){
                maskDom.style.display="block"
            },10)
        }
    },
    hide: function (parentDom){
        setTimeout(() => {
            if (!parentDom && document.getElementById("mainContent")) {
                parentDom = document.getElementById("mainContent").getElementsByTagName("div")[0];
            } else if (!parentDom) {
                parentDom = document.getElementsByTagName("body")[0];
            }
            if (document.getElementById("paperMask")) {
                document.getElementById("paperMask").style.display = "none";
                parentDom.style.overflow = "auto";
            }
        }, 20);
    }
}

export default mask;