import React from 'react';
import SettingsInputAntenna from '@material-ui/icons/SettingsInputAntenna';
import Group from '@material-ui/icons/Group';
import VPNClient from './views/vpnClient/vpnClient'
import UserManager from './views/userManager/userManager'

export default [
    {
        id:"vpnClient",
        icon: <SettingsInputAntenna />,
        text:"VPN Client",
        component: VPNClient,
        roles:[1,2,3]
    },
    {
        id: "userManager",
        icon: <Group />,
        text: "账号管理",
        component: UserManager,
        roles: [1]
    }
]
