import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { withSnackbar } from 'notistack';
import { withRouter } from "react-router-dom";
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import axios from '../../utils/axios';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import mask from '../../utils/mask'

const styles = theme => ({
    root: {},
    loginBG: {
        background: "url(" + require("./loginBG.png") + ") no-repeat",
        backgroundSize: "100% 100%",
        position: "absolute",
        top: "72px",
        left: "0",
        right: "0",
        bottom: "0"
    },
    header: {
        height: "72px",
        lineHeight: "72px",
        textAlign: "center",
        background: "#171719",
        color: "#fff",
        fontSize: "24px",
        fontWeight: "700"
    },
    loginCon: {
        position: "absolute",
        left: "50%",
        top: "50%",
        transform: "translate(-50%,-50%)",
        width: "600px",
        height: "640px",
        background: "rgba(188, 239, 223, 0.75)",
        padding: "60px"
    },
    loginHead: {
        textAlign: "center",
        fontSize: "42px",
        paddingTop: "20px",
        lineHeight: "50px",
        fontWeight: "700",
        fontFamily: "'Roboto Condensed', sans-serif"
    },
    line: {
        width: "55px",
        height: "4px",
        margin: "8px auto 80px",
        background: "#ff9800"
    },
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        background: "#fff",
        width: "100%",
        margin: "0px !important"
    },
    inputCon: {
        padding: "4px 4px 0",
        borderRadius: "4px",
        background: "#fff",
        width: "100%",
        marginBottom: "30px",
        height: "52px",
        paddingTop: "8px",
        overflow: "hidden"
    },
    button: {
        marginTop: "80px",
        width: "100%",
        height: "50px",
        fontSize: "18px",
        boxShadow: " 0 1px 5px rgba(0,0,0,0.2)"
    }
});

class Login extends React.Component {
    state = {
        username: "",
        password: "",
        oldpwd:"",
        newpwd:"",
        newpwd2:"",
        open:false
    }

    toLogin = () => {
        if (!this.state.username) {
            this.props.enqueueSnackbar("请输入用户名", { variant: 'error' });
        } else if (!this.state.password) {
            this.props.enqueueSnackbar("请输入密码", { variant: 'error' });
        } else {
            mask.show();
            axios.post("/admin/login", {
                userName: this.state.username,
                password: this.state.password
            }).then((response) => {
                mask.hide();
                window.storage.setItem("userinfo", { 
                    id: response.resData.id, 
                    realName: response.resData.realName, 
                    userName: response.resData.userName, 
                    roleId: response.resData.roleId, 
                    status:response.resData.status
                });
                if (!response.resData.passwordChange) {// 没有修改过密码
                    this.setState({
                        open:true
                    })
                } else {
                    this.props.history.push("/index/vpnclient");
                }
            }, (err) => {
                    this.props.enqueueSnackbar(err, { variant: 'error' });
                mask.hide();
            })
        }
    }

    handleChange = name => event => {
        this.setState({
            [name]: event.target.value
        })
    }

    onKeyup = (e) => {
        if (e.keyCode === 13) {
            this.toLogin()
        }
    }

    onKeyup1 = (e) => {
        if (e.keyCode === 13) {
            this.handleSubmit()
        }
    }

    handleClose = () => {
        this.setState({
            oldpwd: "",
            newpwd: "",
            newpwd2: "",
            open: false
        })
    }

    handleSubmit=()=>{
        if (!this.state.oldpwd){
            this.props.enqueueSnackbar("请输入原密码", { variant: 'error' });
        }else if(this.state.newpwd !== this.state.newpwd2){
            this.props.enqueueSnackbar("新密码两次输入不一致", { variant: 'error' });
        } else if (this.state.newpwd.length < 6){
            this.props.enqueueSnackbar("新密码长度不能少于6位", { variant: 'error' });
        }else{
            mask.show();
            axios.post("/user/changePassword",{
                userName: window.storage.getItem("userinfo")["userName"],
                oldPassword: this.state.oldpwd,
                newPassword: this.state.newpwd
            }).then((response)=>{
                mask.hide();
                this.props.history.push("/index/vpnclient");
            }, (err) => {
                this.props.enqueueSnackbar(err, { variant: 'error' });
                mask.hide();
            })
        }
    }

    componentDidMount() {
        window.storage.clear();
    }

    render() {
        const { classes } = this.props;
        return (
            <div className={classes.root}>
                <div className={classes.header}>
                    <span style={{ color: "#ff9b00", marginRight: "10px" }}>MENUSIFU</span>
                    <span>Linux Box Server</span>
                </div>
                <div className={classes.loginBG}></div>
                <div className={classes.loginCon}>
                    <div className={classes.loginHead}>SIGN IN</div>
                    <div className={classes.line}></div>
                    <div className={classes.inputCon}>
                        <TextField
                            id="username"
                            label="name"
                            className={classes.textField}
                            value={this.state.username}
                            onChange={this.handleChange('username')}
                            onKeyUp={this.onKeyup}
                        />
                    </div>
                    <div className={classes.inputCon}>
                        <TextField
                            id="password"
                            label="password"
                            type="password"
                            className={classes.textField}
                            value={this.state.password}
                            onChange={this.handleChange('password')}
                            onKeyUp={this.onKeyup}
                        />
                    </div>
                    <Button variant="contained" className={classes.button} color="primary" onClick={this.toLogin}>
                        登 录
                    </Button>
                </div>

                <Dialog open={this.state.open} onClose={this.handleClose} aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">修改密码</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            目前您的账户密码为默认密码，请修改。
                        </DialogContentText>
                        <TextField
                            margin="dense"
                            id="oldpwd"
                            label="原密码"
                            type="password"
                            value={this.state.oldpwd}
                            fullWidth
                            onChange={this.handleChange('oldpwd')}
                            onKeyUp={this.onKeyup1}
                        />
                        <TextField
                            margin="dense"
                            id="newpwd"
                            label="新密码"
                            type="password"
                            value={this.state.newpwd}
                            fullWidth
                            onChange={this.handleChange('newpwd')}
                            onKeyUp={this.onKeyup1}
                        />
                        <TextField
                            margin="dense"
                            id="newpwd2"
                            label="确认密码"
                            value={this.state.newpwd2}
                            type="password"
                            fullWidth
                            onChange={this.handleChange('newpwd2')}
                            onKeyUp={this.onKeyup1}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleClose} >
                            取消
                        </Button>
                        <Button onClick={this.handleSubmit} color="primary">
                            确定
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        )
    }
}

export default withRouter(withStyles(styles)(withSnackbar(Login)));