import React from 'react';
import ReactDOM from 'react-dom';
import App from './app';
import { SnackbarProvider } from 'notistack';
import CssBaseline from '@material-ui/core/CssBaseline';
import { BrowserRouter as Router } from "react-router-dom";
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/Button';
import './static/css/Material_icon.css'
import './static/css/Material_roboto.css'
import './static/css/normalize.css'
import './static/css/style.css'
import { MuiThemeProvider } from '@material-ui/core/styles';
import theme from './theme'

ReactDOM.render(
  <Router>
    <MuiThemeProvider theme={theme}>
      <CssBaseline />
      <SnackbarProvider maxSnack={3} hideIconVariant
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transitionDuration={{ exit: 150, enter: 350 }}
        autoHideDuration={2600}
        style={{ fontSize: "16px" }}
        action={[
          <IconButton key="closeSnackbar" size="small"><CloseIcon style={{ color: "#fff", fontSize: "18px" }} /></IconButton>
        ]}>
        <App />
      </SnackbarProvider>
    </MuiThemeProvider>
  </Router>
  , document.getElementById('root'));